tr {
  border: 0;
}
tr.border,
tr.border {
  border: none;
  padding: 0;
}

tr.border th,
tr.border td {
  border: none;
  padding: 0;
}

tr.border th > div,
tr.border td > div {
  /* padding: 12px 24px; */
  border: 1px #70707036 solid;
  border-left: none;
  border-right: none;
  border-radius: 0;
  height: 50px;
  display: flex;
  align-items: center;
}
tr.border td > div {
  margin-top: 20px;
  padding: 10px;
}

tr.border th:first-child > div,
tr.border td:first-child > div {
  border-left: 1px #70707036 solid;
  padding-right: 0;
  border-radius: 10px 0px 0px 10px;
  color: transparent;
}
tr.border th:last-child > div,
tr.border td:last-child > div {
  border-right: 1px #70707036 solid;
  border-radius: 0px 10px 10px 0px;
}

.input-register {
  font-size: 14px !important;
  border-radius: 6px !important;
  border-color: #70707033 !important;
}
